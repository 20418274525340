import React from "react";
import {
  List,
  ProgressBar,
  Tabs,
  Fieldset,
  Checkbox,
  Input,
  Dropdown,
  Tab,
} from "@react95/core";
import { Shell3242 } from "@react95/icons";
import "./97.css";
import * as S from "./layoutStyling";

function Skills({ closeSkills, isMobile }) {
  return (
    <S.layoutMain
      isMobile={isMobile}
      title={"Skills.txt"}
      closeModal={closeSkills}
      icon={<Shell3242 variant="16x16_4" />}
      menu={[
        {
          name: "Options",
          list: (
            <List>
              <List.Item onClick={closeSkills}>Close</List.Item>
            </List>
          ),
        },
      ]}
    >
      <S.layoutMainContent bg="white" boxShadow="in">
        <S.textModal>
          <p>
            {" "}
            <h1>Some of my skills</h1>I disagree with quantifying skills with
            numbers, percentages etc. - are skills really something people can
            measure? However, here is a rough estimate of my latest skills.
            <h2>Competency Matrix</h2>
            Beginner - 1 - (0-20%) - Beginner <br />
            Intermediate - 2 - (21-40%) - Intermediate <br />
            Advanced - 3 - (41-60%) - Personal project and freelance experience{" "}
            <br />
            Professional 4 - (61-80%) - Professional Work experience <br />
            Expert - 5 - (81-100%) - 5+ years of personal and professional Work
            experience <br />
            <br />
            <hr />
            <h2>Quick Overview:</h2>
            <ul class="tree-view">
              <li>
                Skills:
                <li>
                  <details open>
                    <summary>Programming languages</summary>
                    <ul>
                      <li>Javascript</li>
                      <li>Typescript</li>
                      <li>HTML & CSS</li>
                      <li>SCSS</li>
                      <li>less</li>
                      <li>Solidity</li>
                      <li>Python</li>
                      <li>JQuery</li>
                      <li>PHP</li>
                      <li>Rust</li>
                      <li>Golang</li>
                      <li>Vyper</li>
                      <li>C++,</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Frameworks</summary>
                    <ul>
                      <li>Truffle Suite</li>
                      <li>Hardhat</li>
                      <li>Foundry-rs</li>
                      <li>Node</li>
                      <li>Express</li>
                      <li>React</li>
                      <li>Gatsby</li>
                      <li>Next.js</li>
                      <li>Mocha</li>
                      <li>Chai</li>
                      <li>Tailwind CSS</li>
                      <li>Nest.js</li>
                      <li>Bootstrap</li>
                      <li>Yii 2</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Methodology</summary>
                    <ul>
                      <li>Agile/Scrum</li>
                      <li>Waterfall</li>
                      <li>RAD</li>
                      <li>TDD</li>
                      <li>DevOps</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Blockchains</summary>
                    <ul>
                      <li>Ethereum </li>
                      <li>BSC</li>
                      <li>Polygon</li>
                      <li>Polygon zkEVM</li>
                      <li>Solana</li>
                      <li>Arbitrum</li>
                      <li>Optimism</li>
                      <li>zkSync</li>
                      <li>Cosmos</li>
                      <li>Fantom</li>
                      <li>Polkadot</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Databases</summary>
                    <ul>
                      <li>MySQL</li>
                      <li>PostgreSQL</li>
                      <li>MongoDB</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Web 3 Tools & Libraries</summary>
                    <ul>
                      <li>Web3.js</li>
                      <li>Ethers.js</li>
                      <li>eth-Brownie</li>
                      <li>Flashbots, </li>
                      <li>DeFi & DEX</li>
                      <li>Tenderly</li>
                      <li>OpenZeppelin</li>
                      <li>Geth Client</li>
                      <li>Nethermind Client</li>
                      <li>Dune Analytics</li>
                      <li>TheGraph</li>
                      <li>Gelato</li>
                      <li>Mythril, MythX</li>
                      <li>Slither</li>
                      <li>Remix</li>
                      <li>MultiSigs (e.g. SAFE)</li>
                      <li>Blockscout</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Version Control</summary>
                    <ul>
                      <li>Git</li>
                      <li>Github</li>
                      <li>Bitbucket</li>
                      <li>GitLab</li>
                    </ul>
                  </details>
                  <details>
                    <summary>DevOps, CI/CD</summary>
                    <ul>
                      <li>Jenkins</li>
                      <li>Ansible</li>
                      <li>Apache Kafka</li>
                      <li>Docker</li>
                      <li>Kubernetes</li>
                      <li>Heroku</li>
                      <li>AWS</li>
                    </ul>
                  </details>
                  <details>
                    <summary>OS</summary>
                    <ul>
                      <li>mac OS</li>
                      <li>Ubuntu</li>
                      <li>Kali Linux</li>
                      <li>Debian </li>
                      <li>Black- / Arch Linux</li>
                      <li>ZIION Linux</li>
                      <li>Windows</li>
                      <li>ZSH & BASH</li>
                    </ul>
                  </details>
                  <details>
                    <summary>API</summary>
                    <ul>REST API JSON-RPC WebSocket</ul>
                  </details>
                  <details>
                    <summary>Token & Contract Standards (ERC & EIP)</summary>
                    <ul>
                      <li>ERC-20</li>
                      <li>ERC-721</li>
                      <li>ERC-1155</li>
                      <li>ERC-4626</li>
                      <li>ERC-3156</li>
                      <li>ERC-1967</li>
                      <li>ERC-4337</li>
                      <li>ERC-1167</li>
                      <li>ERC-777</li>
                      <li>ERC-165</li>
                      <li>ERC-173</li>
                      <li>ERC-2535</li>
                      <li>ERC-1271</li>
                      <li>ERC-2612</li>
                      <li>ERC-3525</li>
                      <li>ERC-5805</li>
                      <li>ERC-5982</li>
                      <li>ERC-6120</li>
                      <li>ERC-6357</li>
                      <li>ERC-5005</li>
                      <li>ERC-5164</li>
                      <li>ERC-5679</li>
                      <li>ERC-5615</li>
                      <li>ERC-5528</li>
                      <li>ERC-5564</li>
                      <li>ERC-6956</li>
                      <li>ERC-4824</li>
                    </ul>
                  </details>
                  <details>
                    <summary>Miscellaneous</summary>
                    <ul>
                      <li>Miro</li>
                      <li>Webflow</li>
                      <li>Wordpress</li>
                      <li>Notion</li>
                      <li>Jira / Trello </li>
                      <li>G-Suite</li>
                    </ul>
                  </details>
                </li>
              </li>
            </ul>
            <hr />
            <Tabs
              style={{
                width: "70vh",
              }}
              defaultActiveTab="Programming languages"
            >
              {/* <Tab title="General">
                <Fieldset
                  legend="Logon validation"
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  <Checkbox checked>Log on to Windows NT domain</Checkbox>
                  <br />
                  <p
                    style={{
                      marginLeft: 22,
                      marginTop: 4,
                    }}
                  >
                    When you log on, your password will be verified in a Windows
                    NT domain.
                  </p>
                  <p
                    style={{
                      marginBottom: 4,
                      marginLeft: 22,
                    }}
                  >
                    Windows NT domain:
                  </p>
                  <Input
                    style={{
                      width: 180,
                      marginLeft: 22,
                    }}
                  />
                </Fieldset>

                <Fieldset legend="Network logon options">
                  <Checkbox>Quick logon</Checkbox>
                  <p
                    style={{
                      marginBottom: 4,
                      marginLeft: 22,
                      marginTop: 4,
                    }}
                  >
                    Windows logs you onto the network, but network drives are
                    not reconnected until you use them.
                  </p>
                  <Checkbox>Logon and restore network connections</Checkbox>
                  <p
                    style={{
                      marginBottom: 4,
                      marginLeft: 22,
                      marginTop: 4,
                    }}
                  >
                    When you log onto the network, Windows verifies that each
                    network drive is ready to use.
                  </p>
                </Fieldset>
              </Tab>
              <Tab title="Compatibility">
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: "1.6em",
                  }}
                >
                  If you have problems with this program and it worked correctly
                  on an earlier version of Windows, select the compatibility
                  mode that matches that earlier version.
                </p>

                <Fieldset
                  legend="Compatibility mode"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <Checkbox checked>
                    Run this program in compatibility mode for:
                  </Checkbox>
                  <Dropdown
                    style={{
                      width: 200,
                    }}
                    options={["Windows 95"]}
                  />
                </Fieldset>

                <Fieldset legend="Display Settings">
                  <Checkbox>Run in 256 colors</Checkbox>
                  <Checkbox>Run in 640 x 480 screen resolution</Checkbox>
                  <Checkbox>Disable visual themes</Checkbox>
                </Fieldset>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  Learn more about <a href="#">program compatibility.</a>
                </p>
              </Tab> */}
              <Tab title="Programming languages">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Typescript</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>HTML & CSS</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>SCSS</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Less</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Solidity</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Rust </h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Python </h3>
                  <ProgressBar width={250} percent={40} />
                  <h3>Jquery</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>PHP </h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Golang</h3>
                  <ProgressBar width={250} percent={30} />
                  <h3>Vyper</h3>
                  <ProgressBar width={250} percent={20} />
                  <h3>C++</h3>
                  <ProgressBar width={250} percent={10} />
                </Fieldset>
              </Tab>
              <Tab title="Frameworks">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Truffle Suite</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>Hardhat</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Foundry-rs</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Node</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Express</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>React</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Create React App</h3>
                  <ProgressBar width={250} percent={40} />
                  <h3>Gatsby</h3>
                  <ProgressBar width={250} percent={65} />
                  <h3>Next.js</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Mocha</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Chai</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Tailwind CSS</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>Nest.js</h3>
                  <ProgressBar width={250} percent={40} />
                  <h3>Bootstrap</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Yii 2</h3>
                  <ProgressBar width={250} percent={65} />
                </Fieldset>
              </Tab>
              <Tab title="Blockchains">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Ethereum</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>BSC</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Polygon</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Polygon zkEVM</h3>
                  <ProgressBar width={250} percent={10} />
                  <h3>Solana</h3>
                  <ProgressBar width={250} percent={65} />
                  <h3>Arbitrum</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Opitmism</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>zkSync</h3>
                  <ProgressBar width={250} percent={10} />
                  <h3>Cosmos</h3>
                  <ProgressBar width={250} percent={45} />
                  <h3>Fantom</h3>
                  <ProgressBar width={250} percent={55} />
                  <h3>Polkadot</h3>
                  <ProgressBar width={250} percent={10} />
                </Fieldset>
              </Tab>
              <Tab title="Web3 Tools">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Web3.js</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>Ethers.js</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Ethers-rs</h3>
                  <ProgressBar width={250} percent={25} />
                  <h3>eth-Brownie</h3>
                  <ProgressBar width={250} percent={15} />
                  <h3>Flashbots, DeFi & Dex</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Tenderly</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>OpenZeppelin</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>Geth Client</h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>Nethermind Client</h3>
                  <ProgressBar width={250} percent={50} />
                  <h3>Dune Analytics</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>The Graph</h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>Gelato</h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>Mythril, MythX</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Slither</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>Remix</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>MultiSigs (SAFE)</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>Blockscout</h3>
                  <ProgressBar width={250} percent={15} />
                </Fieldset>
              </Tab>
              <Tab title="API & DevOps">
                <Fieldset
                  legend="API"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>REST API</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>JSON-RPC</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>WebSockets</h3>
                  <ProgressBar width={250} percent={45} />
                </Fieldset>
                <br />
                <Fieldset
                  legend="DevOps"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Jenkins</h3>
                  <ProgressBar width={250} percent={65} />
                  <h3>Ansible</h3>
                  <ProgressBar width={250} percent={15} />
                  <h3>Apache Kafka</h3>
                  <ProgressBar width={250} percent={20} />
                  <h3>Docker</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>Kubernetes</h3>
                  <ProgressBar width={250} percent={25} />
                  <h3>Helm</h3>
                  <ProgressBar width={250} percent={25} />
                  <h3>Minikube</h3>
                  <ProgressBar width={250} percent={30} />
                  <h3>Heroku</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>AWS</h3>
                  <ProgressBar width={250} percent={50} />
                </Fieldset>
              </Tab>
            </Tabs>
            <br />
            <Tabs
              tyle={{
                width: "70vh",
              }}
              defaultActiveTab="OS & Databases"
            >
              <Tab title="OS & Databases">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <Fieldset
                    legend="OS & Shell"
                    style={{
                      marginBottom: "1.6em",
                    }}
                  >
                    <h3>Mac OSX</h3>
                    <ProgressBar width={250} percent={95} />
                    <h3>Ubuntu</h3>
                    <ProgressBar width={250} percent={90} />
                    <h3>Kali Linux</h3>
                    <ProgressBar width={250} percent={85} />
                    <h3>Parrot OS</h3>
                    <ProgressBar width={250} percent={85} />
                    <h3>Debian</h3>
                    <ProgressBar width={250} percent={65} />
                    <h3>Unix "generally"</h3>
                    <ProgressBar width={250} percent={85} />
                    <h3>Black- /Arch Linux</h3>
                    <ProgressBar width={250} percent={45} />
                    <h3>ZIION Linux</h3>
                    <ProgressBar width={250} percent={75} />
                    <h3>Windows</h3>
                    <ProgressBar width={250} percent={80} />
                    <h3>ZSH</h3>
                    <ProgressBar width={250} percent={90} />
                    <h3>BASH</h3>
                    <ProgressBar width={250} percent={90} />
                  </Fieldset>
                  <br />
                  <Fieldset
                    legend="Database"
                    style={{
                      marginBottom: "1.6em",
                    }}
                  >
                    <h3>MySQL</h3>
                    <ProgressBar width={250} percent={65} />
                    <h3>PostgreSQL</h3>
                    <ProgressBar width={250} percent={65} />
                    <h3>MongoDB</h3>
                    <ProgressBar width={250} percent={65} />
                    <h3>Redis</h3>
                    <ProgressBar width={250} percent={15} />
                  </Fieldset>
                </Fieldset>
              </Tab>
              <Tab title="Version Control">
                <Fieldset
                  legend=""
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <Fieldset
                    legend=""
                    style={{
                      marginBottom: "1.6em",
                    }}
                  >
                    <h3>Git</h3>
                    <ProgressBar width={250} percent={85} />
                    <h3>Github</h3>
                    <ProgressBar width={250} percent={90} />
                    <h3>Bitbucket</h3>
                    <ProgressBar width={250} percent={75} />
                    <h3>GitLab</h3>
                    <ProgressBar width={250} percent={15} />
                  </Fieldset>
                </Fieldset>
              </Tab>
              <Tab title="Token/Contract Standards">
                <Fieldset
                  legend="Token/Contract Standards I've worked with"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>ERC-20 </h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>ERC-721 </h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>ERC-1155 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>ERC-4626 </h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>ERC-3156 </h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>ERC-1967 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>ERC-4337</h3>
                  <ProgressBar width={250} percent={65} />
                  <h3> ERC-1167 </h3>
                  <ProgressBar width={250} percent={70} />
                  <h3> ERC-777 </h3>
                  <ProgressBar width={250} percent={50} />
                  <h3> ERC-165 </h3>
                  <ProgressBar width={250} percent={75} />
                  <h3> ERC-173 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3> ERC-2535 </h3>
                  <ProgressBar width={250} percent={55} />
                  <h3> ERC-1271 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3> ERC-2612</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>ERC-3525 </h3>
                  <ProgressBar width={250} percent={45} />
                  <h3>ERC-5805 </h3>
                  <ProgressBar width={250} percent={55} />
                  <h3>ERC-5982 </h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>ERC-6120 </h3>
                  <ProgressBar width={250} percent={75} />
                  <h3>ERC-6357 </h3>
                  <ProgressBar width={250} percent={50} />
                  <h3>ERC-5005 </h3>
                  <ProgressBar width={250} percent={55} />
                  <h3>ERC-5164</h3>
                  <ProgressBar width={250} percent={10} />
                  <h3>ERC-5679 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>ERC-5615 </h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>ERC-5528 </h3>
                  <ProgressBar width={250} percent={10} />
                  <h3>ERC-5564 </h3>
                  <ProgressBar width={250} percent={60} />
                  <h3>ERC-6956 </h3>
                  <ProgressBar width={250} percent={60} />
                  <h3>ERC-4824</h3>
                  <ProgressBar width={250} percent={10} />
                </Fieldset>
              </Tab>
              <Tab title="Methodology">
                <Fieldset
                  legend="Project Managemnet & Coding Methodology"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Agile/Scrum</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>Waterfall</h3>
                  <ProgressBar width={250} percent={80} />
                  <h3>RAD</h3>
                  <ProgressBar width={250} percent={65} />
                  <h3>TDD</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>XP</h3>
                  <ProgressBar width={250} percent={70} />
                  <h3>"DevOps"</h3>
                  <ProgressBar width={250} percent={65} />
                </Fieldset>
              </Tab>
              <Tab title="Misc">
                <Fieldset
                  legend="Miscellaneous Tools and Products"
                  style={{
                    marginBottom: "1.6em",
                  }}
                >
                  <h3>Miro</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>Webflow</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Wordpress</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>Notion</h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>Obsidian</h3>
                  <ProgressBar width={250} percent={85} />
                  <h3>Jira / Trello </h3>
                  <ProgressBar width={250} percent={95} />
                  <h3>G-Suite</h3>
                  <ProgressBar width={250} percent={90} />
                  <h3>Microsoft Office</h3>
                  <ProgressBar width={250} percent={95} />
                </Fieldset>
              </Tab>
            </Tabs>
            <br />
            <hr />
          </p>
        </S.textModal>
      </S.layoutMainContent>
    </S.layoutMain>
  );
}

export default Skills;
