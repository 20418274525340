import React from "react";
import { GlobalStyle, ThemeProvider } from "@react95/core";
import IconBox from "./iconBox";
import IconText from "./iconText";
import "./styles.scss";
import {
  Awfxcg321303,
  Explorer103,
  Mplayer110,
  Mspaint,
  Shell3242,
} from "@react95/icons";

function Shortcuts({
  openPortfolio,
  openCV,
  openTunes,
  openSkills,
  openPaint,
}) {
  return (
    <div>
      <ThemeProvider>
        <GlobalStyle></GlobalStyle>
        <IconBox className="pointer" onClick={() => openPortfolio()}>
          <Explorer103 className="pointer" variant="32x32_4" />
          <IconText className="pointer">Portfolio.txt</IconText>
        </IconBox>
        <IconBox className="pointer" onClick={() => openCV()}>
          <Awfxcg321303 className="pointer" variant="32x32_4" />
          <IconText className="pointer">CV.txt</IconText>
        </IconBox>
        <IconBox className="pointer" onClick={() => openTunes()}>
          <Mplayer110 className="pointer" variant="32x32_4" />
          <IconText className="pointer">Media Player</IconText>
        </IconBox>
        <IconBox className="pointer" onClick={() => openSkills()}>
          <Shell3242 className="pointer" variant="32x32_4" />
          <IconText className="pointer">Skills.txt</IconText>
        </IconBox>
        <IconBox className="pointer" onClick={() => openPaint()}>
          <Mspaint className="pointer" variant="32x32_4" />
          <IconText className="pointer">Paint</IconText>
        </IconBox>
      </ThemeProvider>
    </div>
  );
}

export default Shortcuts;
