import React, { useState, useCallback } from "react";
import {
  GlobalStyle,
  ThemeProvider,
  List,
  Frame,
  Checkbox,
  Alert,
  TaskBar,
} from "@react95/core";
import aminb from "../aminb.png";
import socialMedia from "./socialMedia";
import Shortcuts from "./desktopIcons";
import * as S from "./layoutStyling";
import "./styles.scss";
import "./97.css";
import {
  Progman37,
  Help,
  Mspaint,
  User,
  Shell3242,
  Progman34,
  Mail,
  FolderExe,
  FolderExe2,
  MicrosoftExchange,
  MsDos,
  WindowsExplorer,
  HelpBook,
  Computer3,
  CurvesAndColors100,
} from "@react95/icons";
import Portfolio from "./portfolio";
import CV from "./cv";
import Skills from "./skills";
import Tunes from "./tunes";
import Paint from "./paint";
import Clippyyy from "./clippy";

function Desktop() {
  /* Mobile detection */
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const [items] = useState([]);

  /* About Modal */
  const [showAboutModal, setShowAboutModal] = useState(true);
  const handleOpenAboutModal = useCallback(() => {
    setShowAboutModal(true);
  }, []);
  const handleCloseAboutModal = useCallback(() => {
    setShowAboutModal(false);
  }, []);

  /* Help Modal */
  const [showHelpModal, setShowHelpModal] = useState(false);
  const handleOpenHelpModal = useCallback(() => {
    setShowHelpModal(true);
  }, []);
  const handleCloseHelpModal = useCallback(() => {
    setShowHelpModal(false);
  }, []);

  /* Skills Modal */
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const handleOpenSkillsModal = useCallback(() => {
    setShowSkillsModal(true);
  }, []);
  const handleCloseSkillsModal = useCallback(() => {
    setShowSkillsModal(false);
  }, []);

  /* Photo Modal */
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const handleOpenPhotoModal = useCallback(() => {
    setShowPhotoModal(true);
  }, []);
  const handleClosePhotoModal = useCallback(() => {
    setShowPhotoModal(false);
  }, []);

  /* Portfolio Shortcut */
  const closePortfolio = () => {
    togglePortfolio(false);
  };

  const openPortfolio = () => {
    togglePortfolio(true);
  };
  const [explorerOpened, togglePortfolio] = useState(false);

  /* CV Shortcut */

  const closeCV = () => {
    toggleCV(false);
  };

  const openCV = () => {
    toggleCV(true);
  };

  const [cvOpened, toggleCV] = useState(false);

  /* Tunes Shortcut */

  const closeTunes = () => {
    toggleTunes(false);
  };

  const openTunes = () => {
    toggleTunes(true);
  };

  const [tunesOpened, toggleTunes] = useState(false);

  /* Paint Shortcut */

  const closePaint = () => {
    togglePaint(false);
  };

  const openPaint = () => {
    togglePaint(true);
  };

  const [paintOpened, togglePaint] = useState(false);

  /* Skills Shortcut */
  const closeSkills = () => {
    toggleSkills(false);
  };

  const openSkills = () => {
    toggleSkills(true);
  };

  const [skillsOpened, toggleSkills] = useState(false);

  /* Alert */
  const [showAlert, toggleShowAlert] = React.useState(false);
  const [withSound, toggleWithSound] = React.useState(false);
  
  const handleOpenAlert = () => toggleShowAlert(true);
  const handleCloseAlert = () => toggleShowAlert(false);

  /* Clippy */

  return (
    <ThemeProvider theme="millenium">
      <GlobalStyle></GlobalStyle>
      <Clippyyy></Clippyyy>
      {showAboutModal && (
        <S.layoutMain
          isMobile={isMobile}
          icon=<Help variant="16x16_4" />
          title={"About.txt"}
          closeModal={handleCloseAboutModal}
          menu={[
            {
              name: "Options",
              list: (
                <List>
                  <List.Item onClick={handleCloseAboutModal}>Close</List.Item>
                </List>
              ),
            },
          ]}
        >
          <S.layoutMainContent bg="white" boxShadow="out">
            <S.textModal>
              <h1>Hello there</h1>
              <p>
                I'm Amin, Full Stack Web3 Developer and Cybersecurity Nerd based
                in Zürich, Switzerland. I'm a passionate builder and I love to
                tinker, work on initiatives and learn new things.
              </p>
              <p>
                I've hacked this website together with Create React App. WIP
                <hr />
                @Recruiters: I'm open for new opportunities
                <br />
                @Developers: I'm open for collaborations. Feel free to reach out
                on my socials.
              </p>
              <hr />
              <ul class="tree-view">
                <li>
                  I've hacked this website together using:
                  <ul>
                    <li>Create-React-App</li>
                    <li>babel/core</li>
                    <li>Docker</li>
                    <li>Webpack</li>
                    <li>SCSS</li>
                    <li>@React95/core</li>
                    <li>@React95/icons</li>
                    <li>@98.css</li>
                    <li>JavaScript & TypeScript</li>
                  </ul>
                </li>
              </ul>
              <hr />
              But it's far from completed. I'm still working on it. Next steps
              are:
              <Checkbox checked={false} readonly>
                Use Typescript & .TSX instead of JavaScript
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Refactor Components
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Refactor CSS
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Integrate dynamic Portfolio Page (Blog Backend)
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Add functional cmd
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Add change Theme fx()
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Add Clippy
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Host static data on Arweave (Permaweb)
              </Checkbox>{" "}
              <br />
              <Checkbox checked={false} readonly>
                Add & tinker with fun Web3 functionality
              </Checkbox>{" "}
              <br />
              <span>
                <a
                  href="https://windowswallpaper.miraheze.org/wiki/File:Clouds_(Windows_95).png"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Background
                </a>{" "}
                by WindowsAesthetics /{" "}
                <a
                  href="https://creativecommons.org/licenses/by-sa/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC BY-SA 4.0
                </a>
              </span>
            </S.textModal>
          </S.layoutMainContent>
        </S.layoutMain>
      )}

      {showHelpModal && (
        <S.layoutMain
          isMobile={isMobile}
          closeModal={handleCloseHelpModal}
          height="100%"
          icon={<HelpBook variant="16x16_4" />}
          width={340}
          menu={[
            {
              name: "Options",
              list: (
                <List>
                  <List.Item onClick={handleCloseHelpModal}>Close</List.Item>
                </List>
              ),
            },
          ]}
          title="Help"
        >
          <S.textModal>
            <h2>Desktop Icons/Shortcuts:</h2>
            <h3>Click ... and it ... .</h3>
            Desktop/Portfolio.txt - Opens the Portfolio
            <br />
            Desktop/CV.txt - Shows my CV
            <br />
            Desktop/iTunes - Opens an Apple Music Playlist
            <br />
            Desktop/Skills - Shows my Skills
            <br />
            <hr />
            <h2>Windows Taskbar Icons</h2>
            Start/About - Opens the About Page
            <br />
            Start/Skills - Shows my Skills
            <br />
            Start/Amin - Shows a picture of me
            <br />
            Start/Funny Story - Shows a funny story
            <br />
            Start/Socials - Shows my Socials (click on it and it will redirect
            you, not all are linked yet)
            <br />
            Start/Programs - Shows a list of programs I've built, it will either
            forward you to the Github Repo, Website or open as a new Windows.
            <br />
            <br />
            Hope this helps
            <p></p>
            <p></p>
            <br></br>
          </S.textModal>
        </S.layoutMain>
      )}

      {showPhotoModal && (
        <S.layoutMain
          isMobile={isMobile}
          title={"Amin@ETHGlobal_Paris.png"}
          closeModal={handleClosePhotoModal}
          icon={<User variant="16x16_4" />}
          menu={[
            {
              name: "Options",
              list: (
                <List>
                  <List.Item onClick={handleClosePhotoModal}>Close</List.Item>
                </List>
              ),
            },
          ]}
        >
          <Frame
            boxShadow="none"
            style={{
              margin: "auto",
            }}
          >
            <img
              src={aminb}
              aria-hidden
              alt="Amin@ETHGlobal_Paris.png"
              class="full-width-image"
            ></img>
          </Frame>
          <div class="image-text">
            <p>Amin @ETHGlobal Paris</p>
          </div>
        </S.layoutMain>
      )}

      {showAlert && (
        <Alert
          title="MEV.exe"
          icon={<MsDos variant="16x16_32" style={{ marginRight: "10px" }} />}
          type={"error"}
          message="[WIP] You're not degen enough yet to access this."
          closeAlert={handleCloseAlert}
          hasSound={withSound}
          buttons={[
            {
              value: "OK",
              onClick: handleCloseAlert,
            },
          ]}
        />
      )}
      <TaskBar
        list={
          <List>
            <List.Item
              as="a"
              href="https://t.me/+nopxQEDlPxE3MDg0"
              icon={<Mail variant="32x32_4" style={{ marginRight: "10px" }} />}
              style={{ padding: "5px" }}
              target="_blank"
            >
              Ping me on TG
            </List.Item>
            <List.Item icon={<FolderExe2 variant="32x32_4" />}>
              <List>
                <List.Item
                  icon={
                    <FolderExe
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  Accessories
                </List.Item>
                <List.Item
                  icon={
                    <FolderExe
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  Cybersecurity
                </List.Item>
                <List.Item
                  icon={
                    <FolderExe
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  HTB pwnd Machines & Challenges
                </List.Item>
                <List.Item
                  icon={
                    <FolderExe
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  Hackathons
                </List.Item>
                <List.Item
                  icon={
                    <FolderExe
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  StartUp
                </List.Item>
                <List.Item
                  icon={
                    <MicrosoftExchange
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  Microsoft Exchange
                </List.Item>
                <List.Item
                  icon={
                    <MsDos variant="16x16_32" style={{ marginRight: "10px" }} />
                  }
                  onClick={handleOpenAlert}
                >
                  MEV
                </List.Item>
                <List.Item
                  icon={
                    <MsDos variant="16x16_32" style={{ marginRight: "10px" }} />
                  }
                  onClick={handleOpenAlert}
                >
                  MS-DOS Prompt
                </List.Item>
                <List.Item
                  icon={
                    <Mspaint
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={openPaint}
                >
                  Paint
                </List.Item>
                <List.Item
                  icon={
                    <WindowsExplorer
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  onClick={handleOpenAlert}
                >
                  Windows Explorer
                </List.Item>
              </List>
              Programs
            </List.Item>
            <List.Divider />
            <List.Item
              icon={
                <Progman34 variant="32x32_4" style={{ marginRight: "10px" }} />
              }
              style={{ padding: "5px" }}
            >
              Socials
              <List>
                {socialMedia.map(({ icon, name, url }) => (
                  <List.Item
                    as="a"
                    href={url}
                    icon={icon}
                    key={name}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {name}
                  </List.Item>
                ))}
              </List>
            </List.Item>
            <List.Item
              icon={
                <CurvesAndColors100
                  variant="32x32_4"
                  style={{ marginRight: "10px" }}
                />
              }
              style={{ padding: "5px" }}
            >
              swissDAO
              <List>
                <List.Item
                  href="https://swissdao.space"
                  rel="noopener noreferrer"
                  as="a"
                  icon={
                    <CurvesAndColors100
                      variant="16x16_4"
                      style={{ marginRight: "10px" }}
                    />
                  }
                  style={{ padding: "5px" }}
                >
                  About
                </List.Item>
              </List>
            </List.Item>
            <List.Item
              icon={<User variant="32x32_4" style={{ marginRight: "10px" }} />}
              onClick={handleOpenPhotoModal}
              style={{ padding: "5px" }}
            >
              Amin
            </List.Item>
            <List.Item
              icon={
                <Shell3242 variant="32x32_4" style={{ marginRight: "10px" }} />
              }
              onClick={() => openSkills()}
              style={{ padding: "5px" }}
            >
              Skills
            </List.Item>
            <List.Item
              icon={
                <HelpBook variant="32x32_4" style={{ marginRight: "10px" }} />
              }
              onClick={handleOpenHelpModal}
              style={{ padding: "5px" }}
            >
              Help
            </List.Item>
            <List.Divider />
            <List.Item
              icon={
                <Progman37 variant="32x32_4" style={{ marginRight: "10px" }} />
              }
              onClick={handleOpenAboutModal}
              style={{ padding: "5px" }}
            >
              About
            </List.Item>
            <List.Item
              icon={
                <Computer3 variant="32x32_4" style={{ marginRight: "10px" }} />
              }
            >
              <a
                href="https://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shut Down...
              </a>
            </List.Item>
          </List>
        }
      />
      <React.Fragment>
        <Shortcuts
          openPortfolio={openPortfolio}
          openCV={openCV}
          openTunes={openTunes}
          openSkills={openSkills}
          openPaint={openPaint}
        />
        {explorerOpened && (
          <Portfolio
            items={items}
            closePortfolio={closePortfolio}
            isMobile={isMobile}
          />
        )}
        {cvOpened && <CV items={items} closeCV={closeCV} isMobile={isMobile} />}
        {skillsOpened && (
          <Skills items={items} closeSkills={closeSkills} isMobile={isMobile} />
        )}
        {tunesOpened && (
          <Tunes items={items} closeTunes={closeTunes} isMobile={isMobile} />
        )}
        {paintOpened && (
          <Paint items={items} closePaint={closePaint} isMobile={isMobile} />
        )}
      </React.Fragment>
    </ThemeProvider>
  );
}

export default Desktop;
