import React from "react";

const Boot95 = ({ onVideoEnd }) => (
  <div
    className="loading-screen"
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 999, // Ensure it's on top of other content
    }}
  >
    <video
      width="100%"
      height="100%"
      autoPlay
      playsInline
      onEnded={onVideoEnd}
      controls={false}
      style={{
        objectFit: "cover",
        width: "70vw",
        height: "70vh",
        position: "fixed", // Use fixed to cover the entire screen
        minWidth: "100%",
        minHeight: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", // Center the video
      }}
    >
      <source
        src={`${process.env.PUBLIC_URL}/assets/win95.mp4`}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
);

export default Boot95;
