import React from "react";
import { Frame, List } from "@react95/core";
import { Mspaint } from "@react95/icons";
import * as S from "./layoutStyling";

function Paint({ closePaint, isMobile }) {
  return (
    <S.layoutMain
      isMobile={isMobile}
      title={"Paint"}
      closeModal={closePaint}
      height="100%"
      icon={<Mspaint variant="16x16_4" />}
      menu={[
        {
          name: "Options",
          list: (
            <List>
              <List.Item onClick={closePaint}>Close</List.Item>
            </List>
          ),
        },
      ]}
    >
      <Frame
        boxShadow="none"
        style={{
          height: "450px",
        }}
      >
        
        <iframe
          frameborder="0"
          title="You're Picasso - Paint"
          width="100%"
          height="450px"
          loading="lazy"
          allow=" clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://paint.js.org/"
        />
      </Frame>
    </S.layoutMain>
  );
}

export default Paint;
