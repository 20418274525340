import React from "react";
import { List } from "@react95/core";
import { Defrag } from "@react95/icons";
import * as S from "./layoutStyling";

function Portfolio({ closePortfolio, isMobile }) {
  return (
    <S.layoutMain
      isMobile={isMobile}
      title={"Portfolio.txt"}
      closeModal={closePortfolio}
      height="100%"
      icon=<Defrag variant="16x16_4" />
      menu={[
        {
          name: "Options",
          list: (
            <List>
              <List.Item onClick={closePortfolio}>Close</List.Item>
            </List>
          ),
        },
      ]}
    >
      <S.layoutMainContent bg="white" boxShadow="in">
        <S.textModal>
          <h1>Short Portfolio</h1>

          <p>
            This is a shortened, non-visual version of my portfolio without
            detailed information on the projects I have been working on. If you
            are interested in seeing any of the work I have done in more detail,
            please contact me for a call and I'll present the learnings in more
            detail.
          </p>
          <h2> WIP, please check my Github or available Programs meanwhile</h2>
          <hr />
          <ul class="tree-view">
            <li>
              Public / Private Github, Bitbucket, Gitlab Repositories (WIP on
              Documentation atm.)
              <li>
                <details open>
                  <summary>Web3 Projects (WIP)</summary>
                  <ul>
                    <li>Alpaca Finance Integration - (TypeScript, Solidity)</li>
                    <li>Arrakis x Uni V.3 Vaults - (TypeScript, Solidity)</li>
                    <li>Thena Finance Integration - (TypeScript, Solidity)</li>
                    <li>
                      Arbitrage Ping Module - (TypeScript, Solidity, Shell)
                    </li>
                    <li>
                      Uniswap Arbitrage POC - (TypeScript, Solidity, Shell,
                      Rust)
                    </li>
                    <li>
                      Custom Allowance Module for SAFE MultiSigs - (TypeScript,
                      Solidity)
                    </li>
                    <li>EIP-6956 Code Review & Audit</li>
                    <li>Aave Liquidation POC - (TypeScript, Solidity)</li>
                    <li>Retro Liquity v1 Front-End - (TypeScript, React)</li>
                    <li>
                      Uniswap Crosschain Liquidity Hook - (JavaScript, Solidity,
                      Ruby)
                    </li>
                    <li>
                      Intent solver using Uniswap & SUAVE intents - (Solidity,
                      Rust)
                    </li>
                    <li>
                      Automated token carry swap bot (interest rate ∂) -
                      (TypeScript, Rust, Node, Shell)
                    </li>
                    <li>
                      Probabalistic Uniswap/Sushiswap (...and more) Arbitrage
                      searcher bot using MEV-Share (Rust, TypeScript, Go)
                    </li>
                    <li>
                      Visualized Uniswap V3 Vaults (animated & over time)
                      (TypeScript, JavaScript, HTML, CSS)
                    </li>
                    <li>Web3 OSINT Framework (React, D3.js)</li>
                  </ul>
                </details>
                <details>
                  <summary>Web 2</summary>
                  <ul>
                    <li>
                      SFI E-Commerce B2B Plattform (PHP, HTML, CSS, Yii2
                      Framework)
                    </li>
                    <li>
                      Eden Care (clone Website 1:1 to Webflow, Client didn't
                      want me to change design) (Webflow, Figma)
                    </li>
                    <li>OSINT</li>
                  </ul>
                </details>
              </li>
            </li>
          </ul>
          {/* <h2>Project Name </h2>
          <h3>company</h3>
          <span>
            Role: xxx
          </span>
            <h4>Background</h4>
          <p>
            What is the company doing, background??...
           Elaborate with examples and track record...
          </p>

            <h4>Solution</h4>
            <p>I did x, y z z with this results and challenges and learnings...
            {" "}
            <a href="http://google.com/">I even got a link</a>
            </p>
          <hr />
          <h2>Next Project</h2> */}
        </S.textModal>
      </S.layoutMainContent>
    </S.layoutMain>
  );
}

export default Portfolio;
