import {
  Github,
  Linkedin,
  Twitter,
  Mastodon,
  Medium,
  Telegram,
} from "@styled-icons/boxicons-logos";
import { Ethereum, Substack } from "@styled-icons/simple-icons";
const socialMedia = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/aminb/",
    icon: <Linkedin size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Github",
    url: "https://github.com/d0ra-1h3-3xpl0ra/",
    icon: <Github size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "ENS",
    url: "amin2.eth",
    icon: <Ethereum size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/AminB_CH",
    icon: <Twitter size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Mastodon",
    url: "amin2.eth",
    icon: <Mastodon size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Substack",
    url: "amin2.eth",
    icon: <Substack size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Medium",
    url: "amin2.eth",
    icon: <Medium size="16" style={{ marginRight: "10px" }} />,
  },
  {
    name: "Telegram",
    url: "https://t.me/+nopxQEDlPxE3MDg0",
    icon: <Telegram size="16" style={{ marginRight: "10px" }} />,
  },
];

export default socialMedia;
