import React from "react";
import { List } from "@react95/core";
import { Bookmark } from "@react95/icons";
import * as S from "./layoutStyling";

function CV({ closeCV, isMobile }) {
  return (
    <S.layoutMain
      isMobile={isMobile}
      title={"CV.txt"}
      closeModal={closeCV}
      height="100%"
      icon=<Bookmark variant="16x16_4" />
      menu={[
        {
          name: "Options",
          list: (
            <List>
              <List.Item onClick={closeCV}>Close</List.Item>
            </List>
          ),
        },
      ]}
    >
      <S.layoutMainContent bg="white" boxShadow="in">
        <S.textModal>
          <h1>Curriculum Vitae</h1>
          <p>
            I am a person who is easily integrated into a team with a natural
            talent for handling a wide variety of tasks. I am dynamic,
            resourceful, proactive and understanding, and I have devoted much of
            my career to expanding my knowledge through self- study and
            entrepreneurship. In addition to excellent communication skills, I
            can adapt quickly to a multicultural and remote team, have good
            organizational and analytical skills, and have remarkable strategic
            thinking.
          </p>
          <hr />
          <h2>Latest Work History</h2>
          <h3>
            Co-Founder & Core-Contributor{" "}
            <a
              href="https://swissdao.space"
              target="_blank"
              rel="noopener noreferrer"
            >
              @swissDAO
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>Swiss Web3 Builder Community </li>
              <li>
                Organisation & Coordination of Technical Workshops, Hackathons
                and weekly Builder & Hacking Sessions at TrustSquare Zürich.
              </li>
              <li>
                Worked with Solana Foundation, Gelato, Crypto Valley
                Association, The Graph, CV VC, TrustSquare, Superteam,
                ETHZüri.ch
              </li>
              <li>
                Solidity, Hardhat, Foundry, TypeScript, Rust, Vercel, Next.js,
                Ethers.js, React, TailwindCSS{" "}
              </li>
            </ul>{" "}
            <ul>
              <a
                href="https://swissDAO.space"
                target="_blank"
                rel="noopener noreferrer"
              >
                Company Website
              </a>
              <br></br>
              <a
                href="https://github.com/d0ra-1h3-3xpl0ra/"
                target="_blank"
                rel="noopener noreferrer"
              >
                My Work (Github)
              </a>
            </ul>
          </p>

          <h3>
            DeFi Smart Contract Developer{" "}
            <a
              href="https://grizzly.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              @grizzly.fi
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>Yield Aggregator and Perp. DEX on BSC & Ethereum </li>
              <li>
                Responsible for Smart Contract development, Unit testing &
                Security checks and Frontend Integration.
              </li>
              <li>
                Implementation, optimisation & testing DeFi strategies (multiple
                Grizzly Strategies, Vaults Uniswap v3, Arrakis v1 Fork and
                more).
              </li>
              <li>
                Solidity, Typescript, Node, The Graph, React, Docker, Ethers.js,
                Hardhat, Foundry, Tenderly.
              </li>
            </ul>{" "}
            <ul>
              <a
                href="https://grizzly.fi"
                target="_blank"
                rel="noopener noreferrer"
              >
                Company Website
              </a>
              <br></br>
              <a
                href="https://github.com/d0ra-1h3-3xpl0ra/"
                target="_blank"
                rel="noopener noreferrer"
              >
                My Work (Github)
              </a>
            </ul>
          </p>

          <h3>
            Full-Stack Smart Contract Developer/ MEV Searcher{" "}
            <a
              href="https://etherscan.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Shard
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>Developer-focused DeFi prop. shop</li>
              <li>
                Design, implementation and execution of complex and automated
                DeFi strategies (Arbitrage, MEV, Liquidity Mining) on De-,
                centralised Exchanges (DEX, CEX) and Liquidity Pools (AMM) on
                EVM Blockchains using the latest projects & technologies on
                Ethereum & multiple L2.{" "}
              </li>
              <li>
                I was working with Curve, Compound, Balancer, Uni v2&v3, Aave,
                DyDx, Dodo, Binance, 1Inch, Dune Analytics, Nansen, Flashbots,
                Yearn, Kyber Network, 0x and other platforms & protocols.
              </li>
              <li>
                Solidity, Geth, Typescript, Web3.js, Ethers.js, Hardhat,
                Node.js, REST, JSON-RPC, SQL, ETH Clients, Docker, Golang,
                Vyper, Rust & Python.
              </li>
            </ul>
            <ul>
              <a href="/" target="_blank" rel="noopener noreferrer">
                Company Website
              </a>
              <br></br>

              <a
                href="https://github.com/d0ra-1h3-3xpl0ra/"
                target="_blank"
                rel="noopener noreferrer"
              >
                My Work (Github)
              </a>
            </ul>
          </p>

          <h3>
            Projectmanager & Smart Contract Developer{" "}
            <a
              href="https://nexthype.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Next Hype GmbH
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>Swiss Web2 & Web3 Marketing Firm. </li>
              <li>
                Responsible for implementing and delivering NFT projects for
                artists and brands on the Ethereum Blockchain.
              </li>
              <li>
                Conception and development of NFT projects (The Castello Cube).
              </li>
              <li>Working with Scrum & Agile frameworks in teams of 10. </li>
              <li>
                React, TailwindCSS, Bootstrap, Docker, Solidity, Web3.js,
                Ethers.js, Hardhat, Truffle, Webflow.
              </li>
            </ul>
            <ul>
              <a href="/" target="_blank" rel="noopener noreferrer">
                Company Website
              </a>
            </ul>
          </p>

          <h3>Freelance Smart Contract und Web Developer</h3>
          <p>
            <ul>
              <li>Multiple Web3 DeFi, DAO and NFT Projects. </li>
              <li>
                Solidity, Hardhat, Truffle,. Typescript, Ethers.js, Web3.js,
                Bootstrap, Webflow, Wordpress
              </li>
            </ul>
          </p>
          <h3>
            Projektmanager (Freelance){" "}
            <a
              href="https://www.subs.tv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Subs GmbH
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>German NFT Marketplace built on Flow. </li>
              <li>
                Onboarding & mentoring artists for the Subs NFT platform.{" "}
              </li>
            </ul>
          </p>

          <h3>Co-Founder & CTO @Obsidian Group GmbH</h3>
          <p>
            <ul>
              <li>
                Setting up industrial ASIC mining facilities in the MENA region.
              </li>
              <li>
                Development of proprietary mining optimizer solutions for
                SHA-256 & Scrypt.
              </li>
              <li>
                Development of web-based monitoring dashboards for ASIC Miners.
              </li>
              <li>
                Solidity, TypeScript, React, Node.js, Bootstrap, Truffle,
                Web3.js, Docker
              </li>
            </ul>
          </p>

          <h3>
            Junior Projectmanager & Technical Consultant{" "}
            <a
              href="https://www.moneyhouse.ch/en/company/unity-investment-ag-14573381931"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Unity Investment AG
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>
                Technical consultant for the development and execution of new
                Blockchain projects.
              </li>
              <li>Consulting for Crypto OTC/Brokerage clients.</li>
              <li>
                Responsible for designing, developing and marketing the Token
                (ICO ~7.5 MM CHF in 2018) and the Unicrypt ASIC mining platform.
              </li>
              <li>
                Development of the Unicrypt platform with React, Node.js,
                Bootstrap & JQuery.
              </li>
            </ul>
          </p>

          <h3>
            Junior Front End Developer{" "}
            <a href="/" target="_blank" rel="noopener noreferrer">
              @SFI GmbH
            </a>{" "}
          </h3>
          <p>
            <ul>
              <li>
                Responsible for the Frontend development of the E-Commerce
                Platform for Influencer & Brands (B2B marketing).
              </li>
              <li>
                PHP and Yii2 Framework, HTML, CSS, Wordpress, WooCommerce.
              </li>
            </ul>
          </p>

          <hr />
          <h3> Volunteering / Hackathons </h3>
          <ul>
            <li>Hacker @ETH Online23</li>
            <li>Co-Organisator @Solana Ideathon (Zürich)</li>
            <li>Mentor @Hack Zürich</li>
            <li>Hacker @ETHGlobal Paris</li>
            <li>Hacker @ETH Prague</li>
            <li>Co-Organisator @Wowen Modular Blockchain Hackathon (Zürich)</li>
            <li>Hacker @ETHDAM (Amserdam)</li>
            <li>Mentor @ETHZüri.ch (Zürich)</li>
            <li>Co-Organisator TheGraph Workshop (Tech Workshop@Zürich)</li>
            <li>Co-Organisator Gelato Workshop (Tech Workshop@Zürich)</li>
            <li>Startup Working Group @Crypto Valley Association</li>
            <li>Core-Contributor @swissDAO (Association)</li>
          </ul>
          <hr />
          <h2>Certifications & Education</h2>

          <h3>
            Javascript Algorithms & Datastructures Course{" "}
            <a
              href="http://ude.my/UC-ca071a88-cd40-4639-91da-fe78c1b92dff"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Udemy
            </a>{" "}
          </h3>
          <h3>
            Agile Project Management{" "}
            <a
              href="http://coursera.org/verify/Q8444TGJ58H7"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Google, Coursera
            </a>{" "}
          </h3>

          <h3>
            Web Development Bootcamp{" "}
            <a
              href="http://ude.my/UC-4159fd38-d9fd-4b34-924e-ff939ba31be2"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Udemy
            </a>{" "}
          </h3>

          <h3>
            Full-Stack Software Engineer Course{" "}
            <a
              href="https://www.codecademy.com/learn/paths/full-stack-engineer-career-path"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Codecademy Pro
            </a>{" "}
          </h3>

          <h3>
            Blockchain Bootcamp{" "}
            <a
              href="http://ude.my/UC-94c88789-53bb-40ed-acd3-abeeae3482be"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Udemy
            </a>{" "}
          </h3>
          <h3>Kantonsschule Wettingen</h3>
          <hr />
          <h2>Languages</h2>
          <ul>
            <li>German (fluent)</li>
            <li>English (fluent)</li>
            <li>French (B1)</li>
            <li>Farsi (fluent)</li>
            <li>Kreol (fundamentals)</li>
          </ul>
        </S.textModal>
      </S.layoutMainContent>
    </S.layoutMain>
  );
}

export default CV;
