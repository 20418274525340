import React, { useEffect } from "react";
import { useClippy, ClippyProvider } from "@react95/clippy";
import "@react95/icons/icons.css";

const MyComponent = () => {
  const { clippy } = useClippy();

  useEffect(() => {
    const interval = setInterval(() => {
      clippy.play("Wave");
    }, 20000); //set 20s interval

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [clippy]); // Effect dependencies, re-run if clippy instance changes

  return null;
};

const Clippyyy = () => (
  <ClippyProvider>
    <MyComponent />
  </ClippyProvider>
);

export default Clippyyy;
