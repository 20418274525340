import React, { useState } from "react";
import { GlobalStyle, ThemeProvider } from "@react95/core";
import Page from "./components/mainPage";
import Desktop from "./components/desktop";
import DesktopIcons from "./components/desktopIcons";
import Boot95 from "./components/boot95";
import {Logo} from "@react95/icons";
import { useClippy, ClippyProvider } from '@react95/clippy';
import logo from "./windows95_logo.png";
const Home = () => {
  
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const handleUserInteraction = () => {
    setUserInteracted(true);
  };
return (
  <body class="background">
  
    <div id="fgbg">
  <ThemeProvider>
    <GlobalStyle />
    {!loadingComplete ? (
      userInteracted ? (
        <Boot95 onVideoEnd={() => setLoadingComplete(true)} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} className="enter-site-prompt">
          <button  onClick={handleUserInteraction} style={{ border: 'none', background: 'transparent' }}><img src={logo} alt="Enter Site" height="128" width="128" /><p style={{alignContent: 'center', padding:'5px 1px', background:'rgba(255, 255, 255, 0.5)'}}>Click on Logo to Start</p></button>
        </div>
      )
    ) : (
         
           <Page>
             <Desktop>
    
             </Desktop>         
             {DesktopIcons}
           </Page>
       )}
  </ThemeProvider>
       </div>

  </body>
  );
};

export default Home;