import React from "react";
import { Frame, List } from "@react95/core";
import { Mplayer110 } from "@react95/icons";
import * as S from "./layoutStyling";

function Tunes({ closeTunes, isMobile }) {
  return (
    <S.layoutMain
      isMobile={isMobile}
      title={"Media Player"}
      closeModal={closeTunes}
      height="100%"
      icon={<Mplayer110 variant="16x16_4" />}
      menu={[
        {
          name: "Options",
          list: (
            <List>
              <List.Item onClick={closeTunes}>Close</List.Item>
            </List>
          ),
        },
      ]}
    >
      <Frame
        boxShadow="none"
        style={{
          height: "450px",
        }}
      >
        {/* <iframe
          src="https://open.spotify.com/embed/playlist/42lX7a4yx3rJJcDSimyS9S?utm_source=generator&theme=0"
          width="100%"
          height="352px"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          title="The Ultimate Lo-Fi House Playlist on Spotify"
        /> */}

        <iframe
          // allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
          frameborder="0"
          title="Apple Music Playlist"
          width="100%"
          height="450px"
          loading="lazy"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://embed.music.apple.com/ch/playlist/unter-zeitdruck/pl.29afd72c8b2c40d6b0d06c5c1cf39b6c"
        />
      </Frame>
    </S.layoutMain>
  );
}

export default Tunes;
